.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  margin-left: -30px;
  margin-top: -5px;
  font-family: Inter;
}

.react-tel-input input.form-control {
  padding-right: 58px;
}

.react-tel-input input.form-control::placeholder {
  color: #a29494!important;
}

.hide-flag .flag-dropdown  {
  display: none;
}

.hide-flag .react-tel-input input.form-control {
  padding-left: 0;
  padding-right: 14px;
}

.hide-flag.input-outlined .react-tel-input input.form-control {
  padding-left: 14px;
}
